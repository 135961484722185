import React from 'react'

const Footer = () => (
    <footer className="text-gray-200 body-font bg-gray-800 mt-20">
        <div className="container px-5 py-24 mx-auto flex md:items-center lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col justify-between">
            <div className="flex-grow flex flex-wrap -mb-10 md:mt-0 mt-10 md:text-left text-center justify-start">
                <div className="lg:w-1/2 md:w-1/2 w-full px-4 mr-atuo">
                    <nav className="list-none mb-10 text-sm">
                        <li className="py-2">
                            <a
                                href="https://basicincome.studio.site/privacypolicy"
                                className="text-gray-200 hover:text-gray-400"
                            >
                                プライバシーポリシー
                            </a>
                        </li>
                        <li className="py-2">
                            <a
                                href="https://basicincome.studio.site/tokusho"
                                className="text-gray-200 hover:text-gray-400"
                            >
                                特定商法取引法に基づく表示
                            </a>
                        </li>
                        <li className="py-2">
                            <a
                                href="https://basicincome.studio.site/terms"
                                className="text-gray-200 hover:text-gray-400"
                            >
                                利用規約
                            </a>
                        </li>
                        <li className="py-2">
                            <a
                                href="https://basicincome.studio.site/"
                                className="text-gray-200 hover:text-gray-400"
                            >
                                会社概要
                            </a>
                        </li>
                        <li className="py-2">
                            <a
                                href="https://basicincome.studio.site/contact"
                                className="text-gray-200 hover:text-gray-400"
                            >
                                お問い合わせ
                            </a>
                        </li>
                    </nav>
                </div>
            </div>
        </div>
        <div className="bg-gray-900">
            <div className="container mx-auto py-4 px-5 flex flex-wrap flex-col sm:flex-row">
                <p className="text-gray-500 text-sm text-center sm:text-left">
                    © 2022 Basicincome inc.
                </p>
            </div>
        </div>
        <React.Fragment>
            <script
                dangerouslySetInnerHTML={{
                    __html: `!function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '153894526751157');
      fbq('track', 'PageView');`,
                }}
            />
            <noscript
                dangerouslySetInnerHTML={{
                    __html: `<img height="1" width="1" style="display:none"
      src="https://www.facebook.com/tr?id=153894526751157&ev=PageView&noscript=1" />`,
                }}
            />
        </React.Fragment>
    </footer>
)

export default Footer
