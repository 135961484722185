import React, { useEffect, useState } from 'react'

const FooterBanner = () => {
    const [offset, setOffset] = useState(0)

    useEffect(() => {
        window.onscroll = () => {
            setOffset(window.pageYOffset)
        }
    }, [])

    return offset > 150 && <div className="fixed bottom-0 w-full md:hidden"></div>
}

export default FooterBanner
