import Image from 'next/image'
import Link from 'next/link'
import React from 'react'

import Footer from '../components/footer'
import FooterBanner from '../components/footer/bannaer'
import Header from '../components/header'
import { pagesPath } from '../utils/$path'

import { useSession } from '@/hooks/useSession'
import { supabase } from '@/lib/supabase'
import { Actor } from '@/types/actor'
import CommonMeta from '@/utils/commonMeta'

type Props = {
    actors: Actor[]
}

const Home = (props: Props) => {
    useSession()

    return (
        <>
            <CommonMeta />
            <Header />
            <section className="text-gray-600 body-font subpixel-antialiased">
                <div className="container px-4 mx-auto flex flex-wrap">
                    <div className="md:flex w-full overflow-hidden">
                        <div className="flex-shrink-0 md:mx-0 mx-auto md:w-2/4 w-full md:py-48 py-14">
                            <h2 className="sm:text-4xl text-2xl text-gray-900 font-bold title-font mb-2 w-full leading-loose z-20">
                                夢を一緒に見よう
                                <br />
                                Challenge <br className="md:hidden" />
                                with Basicincome
                            </h2>
                            <div className="w-full ">
                                <p className="text-sm font-bold leading-8">
                                    ベーシックインカム受給者の
                                    <br className="md:hidden" />
                                    「葛藤」と「希望」を描く
                                    <br className="md:hidden" />
                                    ドキュドラマ映画です。
                                </p>
                                <div className="flex md:mt-4 mt-6">
                                    <Link href={pagesPath.about.$url()}>
                                        <a className="inline-flex shadow-sm text-white bg-gradient-to-r from-purple-500 via-purple-600 to-purple-700 border-0 py-1 px-4 focus:outline-none hover:bg-indigo-600 rounded">
                                            ベーシックインカムとは
                                        </a>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="md:w-1/2 w-full h-30 md:h-full md:pb-0 justify-center align-middle flex">
                            <video
                                autoPlay
                                loop
                                muted
                                playsInline
                                className="max-w-none rounded w-full"
                            >
                                <source
                                    src="https://player.vimeo.com/external/629860961.sd.mp4?s=0c864d123f806a002df1702f79c2554a8d3e504b&profile_id=164"
                                    type="video/mp4"
                                />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                {ActorsView(
                    '公開中',
                    'Now Playing',
                    props.actors.filter((i) => i.progress == 1)
                )}
                {ActorsView(
                    'クラウドファンディング',
                    'Cloudfounding',
                    props.actors.filter((i) => i.progress == 0)
                )}
                {ActorsView(
                    '撮影中',
                    'Coming Soon',
                    props.actors.filter((i) => i.progress == 2)
                )}
                {ActorsView(
                    '公開終了',
                    "Actor's History",
                    props.actors.filter((i) => i.progress == 3)
                )}
            </section>

            <FooterBanner />
            <Footer />
        </>
    )
}

const ActorsView = (jp_title: string, sub_title: string, actors: Actor[]) => {
    if (actors.length === 0) {
        return <></>
    }
    return (
        <>
            <div className="container mx-auto flex flex-wrap p-5 ">
                <div className="w-auto">
                    <h2 className="sm:text-2xl text-2xl text-gray-900 font-bold title-font">
                        {jp_title}
                    </h2>
                    <div className="text-xs text-gray-800">{sub_title}</div>
                </div>
            </div>
            <div
                className="container flex flex-row overflow-x-auto screen m-auto mb-6"
                style={{ scrollSnapType: 'x mandatory' }}
            >
                {actors.map((item) => (
                    <Link href={pagesPath.actors._id(item.roman).$url()} key={item.roman}>
                        <a className="pl-4" style={{ scrollSnapAlign: 'start' }}>
                            <div className="w-28">
                                <Image
                                    src={`${process.env.NEXT_PUBLIC_SUPABASE_URL}/storage/v1/object/public/${item.avatar}`}
                                    height={142}
                                    width={142}
                                />
                            </div>
                            <div className="text-1xl text-gray-900 font-bold uppercase">
                                #{item.roman}
                            </div>
                            <div className="text-xs text-gray-500 w-28">{item.dream}</div>
                        </a>
                    </Link>
                ))}
            </div>
        </>
    )
}

export const getStaticProps = async () => {
    let actors = await supabase.from('actors').select(`*`).order('created_at')
    return {
        props: { actors: actors.data },
        revalidate: 1,
    }
}

export default Home
